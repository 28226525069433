<template>
    <div class="card">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h5 class="card-title">View client</h5>
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Company Name</h5>
                {{ company.name }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Company Owner</h5>
                {{ company.owner }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Created By</h5>
                {{ company.created_by }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Responsible</h5>
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Group</th>
                        <th scope="col">Active</th>
                        <th scope="col">Last Login date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in company.responsible" :key="user.id">
                        <th scope="row">{{ user.id }}</th>
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.group }}</td>
                        <td>{{ user.active ? 'Active' : 'Not active' }}</td>
                        <td>{{ user.last_login || 'N/A' }}</td>
                    </tr>
                    </tbody>
                </table>
            </li>

            <li class="list-group-item">
                <h5 class="card-title">Created at</h5>
                {{ company.created_at }}
            </li>
            <li class="list-group-item">
                <h5 class="card-title">Updated at</h5>
                {{ company.updated_at }}
            </li>
        </ul>

        <div class="card-body">
            <router-link :to="{ name: 'EditMyCompany' }" class="card-link">Edit Company</router-link>
        </div>

    </div>
</template>

<script>
import axios from "@/axios";

export default {
    name: "ViewMyCompany",
    data() {
        return {
            company: {},
        }
    },
    created() {
        this.getCompany()
    },
    methods: {
        getCompany() {
            axios.get('/client/company/')
                .then(response => {
                    this.company = response.data.data
                })
        },
    }
}
</script>

<style scoped>
li {
    margin-bottom: 0;
}
</style>